import React from "react";
import type { FilteredViewColumn } from "../../../../../components/FilteredView";
import FilteredView from "../../../../../components/FilteredView";
import type { TestExecution } from "../../../../../@types/sd/testexecutions";
import {
  CapturesInfo,
  GeneralInfo,
  TimeInfo,
} from "../../../../Testing/TestSpecs/ListExecutions/table";

export interface TestExecutionInfo {
  executionId: string;
  testName: string;
}

interface Props {
  executions: TestExecution[];
  onSelect: (testExecution: TestExecution) => void;
}

export const COLUMNS: FilteredViewColumn<TestExecution>[] = [
  {
    id: "detail",
    render: (execution) => <GeneralInfo execution={execution} />,
    weight: 2,
  },
  {
    id: "duration__captures_info",
    render: (execution) => <CapturesInfo execution={execution} />,
    weight: 1,
  },
  {
    id: "duration__execution_time",
    render: (execution) => <TimeInfo execution={execution} />,
    weight: 1,
  },
];

const Executions: React.FC<Props> = ({ executions, onSelect }) => (
  <FilteredView
    title={undefined}
    rawData={executions}
    filters={[]}
    columns={COLUMNS}
    totalWeight={5}
    pageSize={10}
    onRowClick={onSelect}
  />
);

export default Executions;
