import type {
  QueryResult,
  TestExecution,
} from "../../../../@types/sd/testexecutions";

// eslint-disable-next-line import/prefer-default-export
export const groupByTest = (
  executions: QueryResult[]
): Record<string, TestExecution> => {
  const grouped = {};

  executions.forEach((e) => {
    const execution = e.execution;
    const testName = execution.spec.test;
    if (!grouped[testName]) {
      grouped[testName] = execution;
    }
  });

  return grouped;
};
