import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./TestCollapseTitle.module.css";
import type { TestExecutionStatus } from "../../../../../@types/sd/testexecutions";
import type { TestCollapseWithTestType } from "./utils";
import { getExtraDetailsForRendering } from "./utils";

export type TestCollapseTitleProps = {
  testName: string;
  executionStatus: TestExecutionStatus;
  executionName?: string;
} & TestCollapseWithTestType;

const TestCollapseTitle = ({
  testName,
  executionStatus,
  executionName,
  ...props
}: TestCollapseTitleProps) => {
  const renderingData = getExtraDetailsForRendering({
    ...props,
    testName,
    executionStatus,
  });

  let prefixTitle = testName;
  if (executionName) {
    prefixTitle = `${testName} (${executionName})`;
  }

  return (
    <div className={styles.wrapper}>
      <Icon
        icon={renderingData.icon.icon}
        className={renderingData.icon.className}
      />
      <span>
        {prefixTitle}: {renderingData.description}
      </span>
    </div>
  );
};

export default TestCollapseTitle;
