import React from "react";
import { Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import useGetTestExecutionDetails from "../../../Testing/TestExecutionDetail/useGetTestExecutionDetails";
import Error from "../../../../components/Error/Error";
import { TestDiff } from "../../../Testing/TestExecutionDetail/TestDiff";
import { TestCheck } from "../../../Testing/TestExecutionDetail/TestCheck";

type TestExecutionProps = {
  textExecutionName?: string;
  testName?: string;
};

const TestExecution = ({ testName, textExecutionName }: TestExecutionProps) => {
  const testExecutionDetails = useGetTestExecutionDetails({
    testName,
    executionName: textExecutionName,
  });
  const {
    raw: { executionDetail },
    tests: {
      diff: { controlProps, setControlProps, filteredFindings },
    },
  } = testExecutionDetails;

  if (executionDetail.error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (executionDetail.error.response?.status === 400) {
      return <Error text={`${textExecutionName} not found`} />;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return (
      <Spinner
        className="flex h-screen"
        intent={Intent.PRIMARY}
        size={SpinnerSize.STANDARD}
      />
    );
  }

  return (
    <>
      <TestDiff
        controlProps={controlProps}
        onControlPropChange={setControlProps}
        executionName={textExecutionName}
        testName={testName}
        executionStatus={executionDetail.data.status.phase}
        executionDetails={executionDetail.data}
        filteredFindings={filteredFindings}
        showExtraDetails
      />
      <TestCheck
        executionName={textExecutionName}
        testName={testName}
        executionStatus={executionDetail.data.status.phase}
        checkResults={executionDetail.data?.results?.checks}
        showExtraDetails
        executionDetails={executionDetail.data}
      />
    </>
  );
};

export default TestExecution;
