import React, { useMemo } from "react";
import classNames from "classnames";
import pluralize from "pluralize";
import type { TestExecution } from "../../../../../@types/sd/testexecutions";
import styles from "./SummaryExecutions.module.css";
import type {
  AggregatedChecks,
  AggregatedDiffs,
  AggregatedExecutions,
} from "./utils";
import { aggregateChecks, aggregateDiffs, aggregateExecutions } from "./utils";

type SummaryExecutionsProps = {
  executions: TestExecution[];
};

const TestsSummary = ({
  total,
  aggregated,
}: {
  total: number;
  aggregated: AggregatedExecutions;
}) => {
  const { running, pending, failed, succeeded } = aggregated;

  return (
    <div className={classNames(styles.summary_card, styles.card_tests)}>
      <h3>Tests</h3>
      <div className={styles.details}>
        <span className={styles.description_diff}>
          {total}
          <span> {pluralize("tests", total)} ran</span>
        </span>

        {running > 0 && (
          <span className={classNames(styles.description_diff)}>
            {running}
            <span> {pluralize("test", running)} running</span>
          </span>
        )}
        {pending > 0 && (
          <span className={classNames(styles.description_diff)}>
            {pending}
            <span> {pluralize("test", pending)} pending to run</span>
          </span>
        )}
        {failed > 0 && (
          <span className={classNames(styles.description_diff)}>
            {failed}
            <span> {pluralize("test", failed)} failed run</span>
          </span>
        )}
        {succeeded > 0 && (
          <span className={classNames(styles.description_diff)}>
            {succeeded}
            <span> {pluralize("test", succeeded)} succeeded run</span>
          </span>
        )}
      </div>
    </div>
  );
};

const DiffSummary = ({ aggregated }: { aggregated: AggregatedDiffs }) => {
  const {
    totalDiff,
    count: { red, yellow, green },
  } = aggregated;

  return (
    <div className={classNames(styles.summary_card, styles.card_diff)}>
      <h3>Diff</h3>
      <div className={styles.details}>
        <span className={styles.description_diff}>
          {totalDiff}
          <span> {pluralize("difference", totalDiff)} found</span>
        </span>

        {red > 0 && (
          <span
            className={classNames(
              styles.description_diff,
              styles.description_diff_high
            )}
          >
            {red}
            <span> high {pluralize("difference", red)} found</span>
          </span>
        )}
        {yellow > 0 && (
          <span
            className={classNames(
              styles.description_diff,
              styles.description_diff_medium
            )}
          >
            {yellow}
            <span> medium {pluralize("difference", yellow)} found</span>
          </span>
        )}
        {green > 0 && (
          <span
            className={classNames(
              styles.description_diff,
              styles.description_diff_low
            )}
          >
            {green}
            <span> low {pluralize("difference", green)} found</span>
          </span>
        )}

        <span className={styles.helper_text}>
          Diff across all executed tests
        </span>
      </div>
    </div>
  );
};

const ChecksSummary = ({ aggregated }: { aggregated: AggregatedChecks }) => {
  const {
    totalChecks,
    count: { fail },
  } = aggregated;

  return (
    <div className={classNames(styles.summary_card, styles.card_checks)}>
      <h3>Checks Results</h3>
      <span className={styles.description_checks}>
        {fail}
        <span> checks failed of</span>
        <span> {totalChecks}</span>
      </span>
    </div>
  );
};

const SummaryExecutions = ({ executions }: SummaryExecutionsProps) => {
  const aggregatedExecutions = useMemo(
    () => aggregateExecutions(executions),
    [executions]
  );

  const aggregatedDiffs = useMemo(
    () => aggregateDiffs(executions),
    [executions]
  );

  const aggregatedChecks = useMemo(
    () => aggregateChecks(executions),
    [executions]
  );

  return (
    <div className={styles.container}>
      <h2>Summary</h2>
      <div className={styles.summary}>
        <TestsSummary
          total={executions.length}
          aggregated={aggregatedExecutions}
        />
        <DiffSummary aggregated={aggregatedDiffs} />
        <ChecksSummary aggregated={aggregatedChecks} />
      </div>
    </div>
  );
};

export default SummaryExecutions;
