import classNames from "classnames";
import { Icon } from "@blueprintjs/core";
import React from "react";
import styles from "./TestCheck.module.css";

type TestCheckCollapsedProps = {
  totalPassed: number;
  totalFailed: number;
  totalSkipped: number;
  onExpand: () => void;
};

const TestCheckCollapsed = ({
  totalPassed,
  totalFailed,
  totalSkipped,
  onExpand,
}: TestCheckCollapsedProps) => {
  const total = totalSkipped + totalPassed + totalFailed;

  return (
    <div className={styles.container}>
      <div className={styles.summary_checks}>
        <div
          className={classNames(styles.status, styles.status_pass, {
            [styles.status_opaque]: totalPassed === 0,
          })}
        >
          <Icon size={16} icon="tick-circle" />
          <span>
            {totalPassed}/{total}
          </span>
          PASS
        </div>
        <div
          className={classNames(styles.status, styles.status_fail, {
            [styles.status_opaque]: totalFailed === 0,
          })}
        >
          <Icon size={16} icon="cross-circle" />
          <span>
            {totalFailed}/{total}
          </span>
          FAIL
        </div>
        {/* <div */}
        {/*  className={classNames(styles.status, styles.status_skipped, { */}
        {/*    [styles.status_opaque]: true, */}
        {/*  })} */}
        {/* > */}
        {/*  <Icon size={16} icon="desktop" /> */}
        {/*  <span> */}
        {/*    {totalSkipped}/{total} */}
        {/*  </span> */}
        {/*  SKIPPED */}
        {/* </div> */}
      </div>

      {total > 0 && (
        <div className={styles.wrapper_bottom}>
          <button onClick={onExpand}>
            <Icon icon="chevron-down" size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default TestCheckCollapsed;
