import React, { useCallback, useMemo } from "react";
import type { ControlProps } from "./common/Controls/Controls";
import Controls, { ViewType } from "./common/Controls/Controls";
import EmptyFindings from "./common/EmptyFindings/EmptyFindings";
import FlatView from "./views/FlatView";
import GroupedView from "./views/GroupedView/GroupedView";
import type { TrafficDiffFinding } from "../../../@types/sd/findings";
import { filterFindings } from "./util";
import { useGetFindings } from "../../../api/FindingsApi";
import Collapse from "./common/Collapse";
import TestCollapseTitle from "./common/TestCollapseTitle/TestCollapseTitle";
import styles from "./TestDiff.module.css";
import { TestDiffCollapsed } from "./TestDiffCollapsed";
import type { TestTypeView } from "./common";

export type TestDiffProps = {
  controlProps: ControlProps;
  onControlPropChange: (cp: ControlProps) => void;
  filteredFindings: TrafficDiffFinding[];
} & TestTypeView;

export const TestDiff = ({
  testName,
  executionName,
  controlProps,
  onControlPropChange,
  executionStatus,
  showExtraDetails,
  filteredFindings,
}: TestDiffProps) => {
  const { high, medium, low } = controlProps.findingsCount;

  const showFindings = high + medium + low > 0;

  const findingsCall = useGetFindings(
    testName!,
    executionName!,
    0,
    undefined,
    undefined,
    { enabled: showFindings }
  );

  const TestDiffCollapsedCached = useCallback(
    (onExpand: VoidFunction) => (
      <TestDiffCollapsed
        {...controlProps}
        onExpand={onExpand}
        containDiff={showFindings}
      />
    ),
    [controlProps.findingsCount]
  );

  return (
    <Collapse
      collapsedElement={TestDiffCollapsedCached}
      show={testName !== undefined && executionName !== undefined}
      title={
        <TestCollapseTitle
          testName={testName as string}
          executionStatus={executionStatus}
          testType="diff"
          findingsCount={controlProps.findingsCount}
          executionName={showExtraDetails ? executionName : undefined}
        />
      }
      headerStyle={styles.header}
      bodyStyle={styles.container}
      defaultOpen={false}
    >
      {showFindings ? (
        <>
          {(findingsCall.error || !findingsCall.isFetched) &&
            "No findings available"}
          {findingsCall.isSuccess && (
            <>
              <Controls
                initialValue={controlProps}
                onChange={onControlPropChange}
              />

              {filteredFindings.length === 0 && <EmptyFindings />}

              {controlProps.viewType === ViewType.Flat ? (
                <FlatView findings={filteredFindings} />
              ) : (
                <GroupedView findings={filteredFindings} testName={testName!} />
              )}
            </>
          )}
        </>
      ) : null}
    </Collapse>
  );
};
