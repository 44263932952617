import React from "react";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./TestDiffCollapsed.module.css";
import type CategoryFilter from "./common/Controls/CategoryFilter";
import style from "./common/Controls/CategoryFilter/CategoryFilter.module.css";

export type TestDiffCollapsedProps = {
  findingsCount: React.ComponentProps<typeof CategoryFilter>["findingsCount"];
  onExpand: () => void;
  containDiff: boolean;
};

export const TestDiffCollapsed = ({
  findingsCount,
  onExpand,
  containDiff,
}: TestDiffCollapsedProps) => (
  <div className={styles.wrapper}>
    <div className={styles.summary}>
      <Popover2>
        <Tooltip2
          content="This classification is based on an AI model and will improve over time."
          intent="none"
          hoverOpenDelay={250}
        >
          <div className={style.title}>
            Relevance <Icon icon="info-sign" className={style.tooltipIcon} />
          </div>
        </Tooltip2>
      </Popover2>
      <div className={styles.filters}>
        <div>
          <div
            className={classNames(styles.filter, styles.filter_high, {
              [styles.active]: findingsCount.high > 0,
            })}
          >
            {findingsCount.high}
          </div>
          <p>High</p>
        </div>
        <div>
          <div
            className={classNames(styles.filter, styles.filter_medium, {
              [styles.active]: findingsCount.medium > 0,
            })}
          >
            {findingsCount.medium}
          </div>
          <p>Medium</p>
        </div>
        <div>
          <div
            className={classNames(styles.filter, styles.filter_low, {
              [styles.active]: findingsCount.low > 0,
            })}
          >
            {findingsCount.low}
          </div>
          <p>Low</p>
        </div>
      </div>
    </div>
    {containDiff && (
      <div className={styles.wrapper_bottom}>
        <button onClick={onExpand}>
          <Icon icon="chevron-down" size={20} />
        </button>
      </div>
    )}
  </div>
);
