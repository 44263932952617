import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { isProd } from "../util/Util";

const useFeatureFlag = () => {
  const [featureIsAvailable, setFeatureIsAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);

    if (!isProd()) {
      setFeatureIsAvailable(true);
      return;
    }
  }, []);

  return {
    isLoading,
    featureIsAvailable,
  };
};

export default useFeatureFlag;
