import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";
import pluralize from "pluralize";
import type {
  CheckResultItem,
  CheckResults,
} from "../../../@types/sd/testexecutions";
import type { FilteredViewColumn } from "../../../components/FilteredView";
import FilteredView from "../../../components/FilteredView";
import Collapse from "./common/Collapse";
import TestCollapseTitle from "./common/TestCollapseTitle/TestCollapseTitle";
import styles from "./TestCheck.module.css";
import type { TestTypeView } from "./common";
import TestCheckCollapsed from "./TestCheckCollapsed";

export type TestCheckProps = {
  checkResults?: CheckResults;
} & TestTypeView;

type CombinedCheckResult = {
  sandbox?: CheckResultItem;
  baseline?: CheckResultItem;
  name: string;
  id: string;
};

/**
 * combineCheckResults combine the checkNames from sandbox and baseline
 * but keeping a reference to both in case exist. These helps in the
 * calculation for rendering filteredView but also to determine whether
 * a check was skipped or not */
const combineCheckResults = (
  checkResults: CheckResults
): CombinedCheckResult[] => {
  const results = new Map<string, CombinedCheckResult>();

  const { sandbox, baseline } = checkResults;

  if (sandbox) {
    sandbox.forEach((checkItem) => {
      const { name } = checkItem;

      results.set(name, {
        sandbox: checkItem,
        name: name,
        id: name,
      });
    });
  }

  if (baseline) {
    baseline.forEach((checkItem) => {
      const { name } = checkItem;

      const existingCombinedItem = results.get(name);
      if (existingCombinedItem) {
        results.set(name, {
          ...existingCombinedItem,
          baseline: checkItem,
        });
        return;
      }

      results.set(name, {
        baseline: checkItem,
        name: name,
        id: name,
      });
    });
  }

  return Array.from(results.values());
};

type StatusBadgeProps = {
  status: "pass" | "fail";
  errors?: CheckResultItem["errors"];
  isOpen?: boolean;
};

const StatusBadge = ({ status, errors, isOpen }: StatusBadgeProps) => {
  if (status === "pass") {
    return (
      <div className={classNames(styles.status, styles.status_pass)}>
        <Icon size={16} icon="tick-circle" className="mr-2" />
        PASS
      </div>
    );
  }

  return (
    <div className={styles.details}>
      <div className={classNames(styles.status, styles.status_fail)}>
        <Icon size={16} icon="cross-circle" className="mr-2" />
        FAIL
      </div>

      {isOpen ? (
        <div className={styles.error_container}>
          <b>Errors</b>
          <ul>
            {errors?.map((err, index) => (
              <li key={`${err.message}-${index}`}>{err.message}</li>
            ))}
          </ul>
        </div>
      ) : (
        <b>
          {errors?.length} {pluralize("error", errors?.length ?? 0)}
        </b>
      )}
    </div>
  );
};

const renderCheckItem = (
  checkItem: CheckResultItem | undefined,
  isDetailsOpen: boolean
) => {
  if (!checkItem) {
    return <span>no ran</span>;
  }

  const { errors } = checkItem;
  if (!errors || errors.length === 0) return <StatusBadge status="pass" />;

  return (
    <StatusBadge
      status="fail"
      errors={errors}
      isOpen={isDetailsOpen || errors.length === 1}
    />
  );
};

const getFilteredViewColumns = (
  openedChecks: string[]
): FilteredViewColumn<CombinedCheckResult>[] => [
  {
    id: "check_name",
    render: (check) => <span>{check.name}</span>,
    weight: 3,
  },
  {
    id: "sandbox",
    render: (check) => {
      const { sandbox } = check;
      return renderCheckItem(sandbox, openedChecks.includes(check.name));
    },
    weight: 1,
  },
  {
    id: "baseline ",
    render: (check) => {
      const { baseline } = check;
      return renderCheckItem(baseline, openedChecks.includes(check.name));
    },
    weight: 1,
  },
];

export const TestCheck = ({
  checkResults,
  testName,
  executionName,
  executionStatus,
  showExtraDetails,
}: TestCheckProps) => {
  const [openedChecks, setOpenedChecks] = useState<string[]>([]);

  if (!testName || !executionName || !checkResults) return null;

  const combinedResults = combineCheckResults(checkResults);

  if (combinedResults.length === 0) return null;

  const total = combinedResults.reduce(
    (previousValue, currentValue) => {
      const { sandbox } = currentValue;
      if (!sandbox) {
        return {
          ...previousValue,
          skipped: previousValue.skipped + 1,
        };
      }

      if (sandbox.errors && sandbox.errors.length > 0) {
        return {
          ...previousValue,
          failed: previousValue.failed + 1,
        };
      }

      return {
        ...previousValue,
        passed: previousValue.passed + 1,
      };
    },
    { passed: 0, failed: 0, skipped: 0 }
  );

  const handleToggleChecks = (checkName: string) => {
    setOpenedChecks((prev) => {
      if (prev.includes(checkName)) {
        return prev.filter((it) => it !== checkName);
      }

      return [...prev, checkName];
    });
  };

  const TestCheckCollapsedCached = useCallback(
    (onExpand: VoidFunction) => (
      <TestCheckCollapsed
        onExpand={onExpand}
        totalPassed={total.passed}
        totalFailed={total.failed}
        totalSkipped={total.skipped}
      />
    ),
    [total]
  );

  return (
    <Collapse
      collapsedElement={TestCheckCollapsedCached}
      show
      title={
        <TestCollapseTitle
          testName={testName as string}
          executionStatus={executionStatus}
          testType="checks"
          checksResult={checkResults}
          executionName={showExtraDetails ? executionName : undefined}
        />
      }
      headerStyle={styles.header}
      bodyStyle={styles.container}
      defaultOpen={false}
    >
      {combinedResults.length > 0 && (
        <FilteredView
          title={undefined}
          columns={getFilteredViewColumns(openedChecks)}
          totalWeight={8}
          columnsHeaders={[
            "Check name",
            "Status (Sandbox)",
            "Status (Baseline)",
          ]}
          rawData={combinedResults}
          onRowClick={(row) => handleToggleChecks(row.name)}
        />
      )}
    </Collapse>
  );
};
