import type { Icon } from "@blueprintjs/core";
import React from "react";
import { TbAlertOctagonFilled } from "react-icons/tb";
import classNames from "classnames";
import { RiAlertFill } from "react-icons/ri";
import { IoInformationCircle } from "react-icons/io5";
import pluralize from "pluralize";
import type { CheckResults } from "../../../../../@types/sd/testexecutions";
import styles from "./TestCollapseTitle.module.css";
import type CategoryFilter from "../Controls/CategoryFilter";
import type { TestCollapseTitleProps } from "./TestCollapseTitle";

export type TestCollapseTitleDiffProps = {
  testType: "diff";
  findingsCount: React.ComponentProps<typeof CategoryFilter>["findingsCount"];
};

export type TestCollapseTitleChecksProps = {
  testType: "checks";
  checksResult: CheckResults;
};

export type TestCollapseWithTestType =
  | TestCollapseTitleDiffProps
  | TestCollapseTitleChecksProps;

type GetRenderingDataReturn = {
  icon: {
    icon: React.ComponentProps<typeof Icon>["icon"];
    className?: string;
  };
  description: string;
};

const getExtraDetailsForDiffRendering = (
  data: TestCollapseTitleDiffProps
): GetRenderingDataReturn => {
  const defaultRendering: GetRenderingDataReturn = {
    icon: {
      icon: "ban-circle",
      className: styles.canceled,
    },
    description: "no diff found",
  };

  const { high, medium, low } = data.findingsCount;

  const DETAILS_MAP: Array<{ isValid: boolean } & GetRenderingDataReturn> = [
    {
      isValid: high > 0,
      icon: {
        icon: (
          <TbAlertOctagonFilled
            className={classNames(styles.failed)}
            size={16}
          />
        ),
      },
      description: `diff found ${high} high relevance ${pluralize(
        "issue",
        high
      )}`,
    },
    {
      isValid: medium > 0,
      icon: {
        icon: <RiAlertFill className={classNames(styles.yellow)} size={16} />,
      },
      description: `diff found ${medium} medium relevance ${pluralize(
        "issue",
        medium
      )}`,
    },
    {
      isValid: low > 0,
      icon: {
        icon: (
          <IoInformationCircle
            className={classNames(styles.succeeded)}
            size={16}
          />
        ),
      },
      description: `diff found ${low} low relevance ${pluralize("issue", low)}`,
    },
  ];

  const selectedDetail = DETAILS_MAP.find((i) => i.isValid);

  return {
    ...(selectedDetail ?? defaultRendering),
  };
};

const getExtraDetailsForChecksRendering = (
  data: TestCollapseTitleChecksProps
): GetRenderingDataReturn => {
  const defaultRendering: GetRenderingDataReturn = {
    icon: {
      icon: "ban-circle",
      className: styles.canceled,
    },
    description: "no checks found",
  };

  const sandbox = data.checksResult.sandbox;
  if (!sandbox) return defaultRendering;

  const totalErrors = sandbox.reduce((previousValue, currentValue) => {
    if (currentValue.errors && currentValue.errors.length > 0)
      return previousValue + 1;

    return previousValue;
  }, 0);

  const DETAILS_MAP: Array<{ isValid: boolean } & GetRenderingDataReturn> = [
    {
      isValid: totalErrors > 0,
      icon: {
        icon: (
          <TbAlertOctagonFilled
            className={classNames(styles.failed)}
            size={16}
          />
        ),
      },
      description: `${totalErrors} ${pluralize("check", totalErrors)} failed`,
    },
    {
      isValid: totalErrors === 0,
      icon: {
        icon: "tick-circle",
        className: styles.succeeded,
      },
      description: "no checks failed",
    },
  ];

  const selectedDetail = DETAILS_MAP.find((i) => i.isValid);

  return {
    ...(selectedDetail ?? defaultRendering),
  };
};

export const getExtraDetailsForRendering = (
  opts: TestCollapseTitleProps
): GetRenderingDataReturn => {
  if (opts.testType === "diff") {
    return getExtraDetailsForDiffRendering(opts);
  }

  return getExtraDetailsForChecksRendering(opts);
};
